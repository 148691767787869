import * as React from "react";
import format from "date-fns/format";

interface LastUpdatedProps {
  updated: string;
}

const LastUpdated: React.FunctionComponent<LastUpdatedProps> = ({
  updated,
}: LastUpdatedProps) => {
  const parsedDate: Date = new Date(Date.parse(updated));
  return <p>Database last updated at {format(parsedDate, "MMM d, h:mm a")}</p>;
};

export default LastUpdated;
