import * as React from "react";
import { useState, useEffect } from "react";

import "../App.css";

import LastUpdated from "../LastUpdated";

import Head from "../components/Head";

const API_URL = `https://api.outages.app/getjson`;

interface APIResponseElement {
  region: string;
  regionData: RegionDatum[];
}

interface RegionDatum {
  date: string;
  locality: string;
  streets: string;
}

interface APIResponse {
  last_update: string;
  data: APIResponseElement[];
}

const initialStateOfData: APIResponse = {
  last_update: "",
  data: [],
};

// data
const links = [
  {
    text: "Tutorial",
    url: "https://www.gatsbyjs.com/docs/tutorial/",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
    color: "#E95800",
  },
  {
    text: "How to Guides",
    url: "https://www.gatsbyjs.com/docs/how-to/",
    description:
      "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
    color: "#1099A8",
  },
  {
    text: "Reference Guides",
    url: "https://www.gatsbyjs.com/docs/reference/",
    description:
      "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
    color: "#BC027F",
  },
  {
    text: "Conceptual Guides",
    url: "https://www.gatsbyjs.com/docs/conceptual/",
    description:
      "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
    color: "#0D96F2",
  },
  {
    text: "Plugin Library",
    url: "https://www.gatsbyjs.com/plugins",
    description:
      "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
    color: "#8EB814",
  },
  {
    text: "Build and Host",
    url: "https://www.gatsbyjs.com/cloud",
    badge: true,
    description:
      "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
    color: "#663399",
  },
];

// markup
const IndexPage = () => {
  const [data, dataSet] = useState<any>(initialStateOfData);

  useEffect(() => {
    const grabData = async () => {
      const responseJSON = await fetch(API_URL);
      const response: APIResponse = await responseJSON.json();
      console.log(response);
      dataSet(response);
    };

    grabData();
  }, []);

  return (
    <div className="App">
      <Head />
      <header className="App-header">{/* <p>Affected Regions</p> */}</header>
      <div className="topPart">
        <div className="topTextWrap">
          <div className="flexspacer"></div>
          <p className="bigTitleText">
            <img
              src={require("../images/CEBOutages4.png").default}
              alt="my image"
            />{" "}
            CEBOutages
          </p>
          <p className="bigBodyText">
            Check for planned power cuts from your favorite electricity provider
            and prepare yourself with candles and stuff.
          </p>
          <div>
            <a
              href="https://apps.apple.com/mu/app/outages/id1570606611?mt=8"
              className="appstoreButtonWrap"
            >
              <img
                src={
                  require("../images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg")
                    .default
                }
                alt="Outages on the App Store"
              />
            </a>
          </div>
          <div className="flexspacer"></div>
          {data.data.regionData === undefined || (
            <p>
              <span className="affectedRegionsSpan">
                Currently-Affected Regions:
              </span>
            </p>
          )}
        </div>
        <div className="topImageWrap">
          <img src={require("../images/iphonemockup.png").default} />
        </div>
      </div>
      <ul className="region-list">
        {data.data.map((response: APIResponseElement, index: number) => {
          if (response.regionData.length === 0) {
            return null;
          }
          return (
            <li key={index}>
              <div className="regiondatum">
                <div className="innerdatum">
                  <h3>{response.region}</h3>
                  <div>
                    {response.regionData.map(
                      (regionDatum: RegionDatum, regionDatumIndex: number) => {
                        return (
                          <div className="regionWrap" key={regionDatumIndex}>
                            <p>{regionDatum.date}</p>
                            <p>{regionDatum.locality}</p>
                            <p>{regionDatum.streets}</p>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      {data.data.regionData === undefined || data.last_update === "" || (
        <LastUpdated updated={data.last_update} />
      )}
      <footer>
        <a href="https://paperboat.io" className="footerLink">
          <div>
            <img
              src={require("../images/paperboat_logo.svg").default}
              className="footerpblogo"
              alt="Paperboat Logo"
            />
            <span className="footerpbcopy">&copy;</span> paperboat
          </div>
        </a>
        <div>
          <a href="/">Home</a>
          <a href="/privacy/">Privacy Policy</a>
          <a href="/terms/">Terms &amp; Conditions</a>
        </div>
      </footer>
    </div>
  );
};

export default IndexPage;
