import React from "react";
import Helmet from "react-helmet";

const Head = () => {
  return (
    <Helmet>
      {/* <!-- Primary Meta Tags --> */}
      <title>
        Outages.app – Find out about planned power outages in Mauritius.
      </title>
      <meta
        name="title"
        content="Outages.app – Find out about planned power outages in Mauritius"
      />
      <meta
        name="description"
        content="Find out about planned power outages in Mauritius, and receive notifications when power cuts are expected in your favourite regions."
      />
      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://outages.app/" />
      <meta property="og:title" content="Outages.app" />
      <meta
        property="og:description"
        content="Find out about planned power outages in Mauritius, and receive notifications when power cuts are expected in your favourite regions."
      />
      <meta
        property="og:image"
        content="https://outages.app/og-thing-screenshot.png"
      />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="outages.app" />
      <meta property="twitter:url" content="https://outages.app" />
      <meta name="twitter:title" content="Outages.app" />
      <meta
        name="twitter:description"
        content="Find out about planned power outages in Mauritius, and receive notifications when power cuts are expected in your favourite regions."
      />
      <meta
        name="twitter:image"
        content="https://outages.app/og-thing-screenshot.png"
      />
    </Helmet>
  );
};

export default Head;
